<template>
  <div>
    <ModaleGenerique ref="modale" :width="'350px'" :is-loading="showProgress">
      <template #activator="{props: activatorProps}">
        <div v-bind="activatorProps" class="ml-2">
          <v-btn icon color="var(--theme-icone)">
            <icon-add_vehicule filled class="add_vehicule" style="font-size: 30px" />
          </v-btn>
        </div>
      </template>
      <template #content>
        <div class="d-flex flex-column">
          <span style="width: 90%" class="font-size-14 mt-2 color-black">
            <b>Renseignez les informations de votre vehicule</b>
          </span>

          <div style="width: 100%" class="d-flex flex-column mt-1">
            <form class="d-flex flex-column justify-center" @submit.prevent="onSubmit">
              <text-input name="immatriculation" label="Immatriculation" />
              <text-input name="numeroDossier" label="N° de contrat" />
              <v-btn class="rounded-pill mx-auto mt-2" width="200px" dark color="var(--theme-icone)" type="submit">
                Valider
              </v-btn>
            </form>
          </div>
        </div>
      </template>
    </ModaleGenerique>
  </div>
</template>
<script setup lang="ts">
import ModaleGenerique from '~/components/ui/modale/modale-generique.vue';
import {useForm} from 'vee-validate';
import {object, string} from 'yup';

const userStore = useUserStore();

const modale = ref(null);

const {handleSubmit} = useForm({
  validationSchema: object({
    immatriculation: string().required('Immtriculation manquante'),
    numeroDossier: string().required('Numero de dossier manquant'),
  }),
});

const closeModale = () => {
  modale.value.closeModale();
};

const onSubmit = handleSubmit(async (values) => {
  await userStore.ajouterVehicule(cleanImmatriculation(values.immatriculation), values.numeroDossier);
  closeModale();
});

const cleanImmatriculation = (str) => {
  return str
    .toUpperCase()
    .normalize('NFD') // Renvoie la forme normalisée Unicode d'une chaîne de caractères
    .replace(/-/g, '')
    .replace(/ /g, '');
};

defineExpose({
  closeModale,
});
</script>
<style scoped>
.icon:hover {
  cursor: pointer;
}

.add_vehicule:deep(.icon) {
  stroke: var(--theme-icone);
  top: 10px;
  right: 10px;
  height: 40px;
}

.add_vehicule:deep(.icon):hover {
  cursor: pointer;
}
</style>
